<template>
  <div id="app" onselectstart="return false">
    <!-- onselectstart="return false"  禁止拖动鼠标选中文字（文字背景变成蓝色） -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.onkeydown = function (event) {
      if (event.ctrlKey && window.event.keyCode === 65) {
        // 禁用ctrl + a 功能
        return false;
      }
    };
  },
};
</script>
<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
.ivu-date-picker {
  cursor: pointer;
}
</style>
