
import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import merge from 'lodash/merge'

// baseUrl
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
// axios.defaults.baseURL = 'http://39.105.54.160:6100'


// const httpw = axios.create({
//     timeout: 1000 * 30,
//     // withCredentials: false,
//     headers: {
//         // 'Content-Type': 'application/json; charset=utf-8'
//         'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
//     }
// });

/**
* 设置拦截器，interceptors 响应处理
* 所有使用 axios 发送的请求，在请求发送出去之前，都会进入到方法中
* 在这里，为所有请求添加上 token 。
*/
axios.interceptors.request.use(config => {
    // 获取token
    const token = sessionStorage.getItem('access_token');
    // 微服务 登录页面 接口api 为https://apiuser.brainsources.net/ 其他页面 接口api为https://apitrain.brainsources.net/
    if (config.url === 'patient/pc/login' || config.url === `patient/info/${token}`
        || config.url === 'patient/detail/checkByPhone' || config.url === 'patient/pc/12nrelation'
    ) {
        if (process.env.NODE_ENV === 'qas') {
            config.baseURL = 'https://apiuser.brainsources.net/';
        } else {
            config.baseURL = 'https://apiuser.brainsources.cn/';
        }

    } else {
        config.baseURL = process.env.VUE_APP_API_HOST;
    }
    if (token) {
        config.headers.common['Authorization'] = `${token}`;
    }
    config.headers.common['deviceId'] = 'deviceId123456789';

    return config
}, function (error) {
    // 对请求错误做些什么
    return error
});

/**
 * 设置拦截器，interceptors 响应处理
 * 所有使用 axios 的请求响应，都会优先回调到拦截器中
 * 在正确的返回情况下，数据会优先进入第一个回调方法
 * 在请求错误的情况下，错误会进入第二个回调方法
 */
axios.interceptors.response.use((response) => {
    //  统一处理数据，使组件中的请求只获取到需要的业务数据，
    // 而不需要去关注返回的状态码等与业务无关的数据
    // console.log('响应拦截器');

    return response.data;
}, (error) => {
    // 处理错误的响应
    if (error.response.status === 456) {
        Vue.prototype.$popup();
        // let Url = window.location.origin
        // console.log(Url);
        // localStorage.clear();
        // sessionStorage.clear();
        // Url = Url + '/login'
        // window.location.href = Url;
    }
    return Promise.reject(error);
});


/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
axios.adornParams = (params = {}, openDefultParams = true) => {
    let defaults = {
        't': new Date().getTime()
    }
    return openDefultParams ? merge(defaults, params) : params
};

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
axios.gets = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err.data)
        })
    });
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
axios.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
    let defaults = {
        't': new Date().getTime()
    };
    let data1 = openDefultdata ? merge(defaults, data) : data;
    return contentType === 'json' ? JSON.stringify(data1) : qs.stringify(data1);
};

axios.jsonContent = () => {
    let defaults = {
        'headers': { 'Content-Type': 'application/json; charset=utf-8' }
    };
    return defaults;
};

//  绑定到 vue 原型中，组件中 ： this.$http -> axios 。

export default axios
Vue.prototype.$http = axios;
