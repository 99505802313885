
import Vue from 'vue';
import '@css/reset.css'
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/css/common.css'
import '@js/axios.config.js'
import '@js/http.js'
import VideoPlayer from 'vue-video-player'
import popups from '../src/components/popup.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import echarts from "@js/echarts.min.js";
// 加入监控
import * as fundebug from "fundebug-javascript";
import FundebugVue from "fundebug-vue";
fundebug.apikey = "31e34afff38927e62823c9ce2e98994efe5e52c7d2837d47912f74633f0c324e"
new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x

// import echarts from 'echarts'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')


Vue.use(VueAwesomeSwiper)
Vue.use(VideoPlayer)
Vue.prototype.$echarts = echarts

Vue.use(ViewUI);
Vue.config.productionTip = false;
const popup = {
    install: function (Vue) {
        // 创建一个Vue的“子类”组件
        const MsgClass = Vue.extend(popups);
        function show(type) {
            // 实例化这个组件
            const instance = new MsgClass();
            // 将组件实例挂在到一个元素上面，如果不传参数就是挂载到body，或者也可以传入其他已经存在的元素的选择器
            instance.$mount(document.createElement('div'));
            // 通过组件实例的$el属性，可以访问到这个组件元素，然后把它拼接到body上。
            document.body.appendChild(instance.$el);
            // 给这个实例传入参数
            instance.isShow = type;

        }
        // 成功时调用这个方法
        Vue.prototype.$popup = () => {
            show(true);
        }


    }
}
// 路由守卫 只能访问login，登录后可以访问其他页面
router.beforeEach((to, from, next) => {
    // 安卓打印功能  打开页面判断 如果路由上有token的话  就放行
    let URL = location.search.substr(1);
    let urlData = {};
    let item = '';
    let items = URL.length ? URL.split("&") : [];
    let length = items.length;
    for (let i = 0; i < length; i++) {
        item = items[i].split("=");
        let name = decodeURIComponent(item[0]);
        let value = decodeURIComponent(item[1]);
        if (name) {
            urlData[name] = value;
        }
    }
    if (urlData.token !== undefined) {
        next()
    } else {
        let isAuthenticated = sessionStorage.getItem('access_token') ? true : false
        if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
        else next()
    }
})
Vue.use(popup)
// iview  $Message全局配置   top距离 还有显示时间
Vue.prototype.$Message.config({
    top: 200,
    duration: 2
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');