
/*eslint-disable*/ 
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//    mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
const state = {   // 要设置的全局访问的state对象
    token: sessionStorage.getItem('access_token') ? sessionStorage.getItem('access_token') : '',
    patientInfo: JSON.parse(sessionStorage.getItem('patientInfo')) ? JSON.parse(sessionStorage.getItem('patientInfo')) : '',
    // 要设置的初始属性值
};
const getters = {   // 实时监听state值的变化(最新状态)
    getCurrentToken(state) {  // 获取当前登录的用户
        return state.token
    },
    getPatientInfo(state) {  // 获取当前登录的用户
        return state.patientInfo
    },
};
const mutations = {
    setCurrentToken(state, user) {  // 同上
        state.token = user;
    },
    setCurrentPatInfo(state, user) {  // 同上
        state.patientInfo = user;
    },
};
const actions = {
    setToken(context, user) {  // 自定义触发mutations里函数的方法，context与store 实例具有相同方法和属性
        context.commit('setCurrentToken', user);
    },
    setPatInfo(context, user) {  // 自定义触发mutations里函数的方法，context与store 实例具有相同方法和属性
        context.commit('setCurrentPatInfo', user);
    },
};
const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
});
export default store;
