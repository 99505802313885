
/*eslint-disable*/ 
import Vue from 'vue'
import request from './request'
import qs from 'qs'
import merge from 'lodash/merge'
const http = {
    /**
     * methods: 请求
     * @param url 请求地址
     * @param params 请求参数
     */
    get(url, params, defultParams = false) {
        const config = {
            method: 'get',
            url: url
        }
        if (params) config.params = this.adornParams(params, defultParams)
        return request(config)
    },
    /**
     * get参数处理
     * @param params
     * {
     *     userId: 8888,
     *     userName: 'DerekFan'
     * }
     * @param openDefultParams 是否增加公共的参数, 可扩展
     * @returns {*}
     */
    adornParams(params = {}, openDefultParams = true) {
        let defaults = {
            't': new Date().getTime()
        }
        return openDefultParams ? merge(defaults, params) : params
    },
    /**
     *
     * @param data
     * {
     *     userId: 8888,
     *     userName: 'DerekFan'
     * }
     * @param openDefultdata 是否增加公共的参数, 可扩展
     * @param contentType json / form
     * @returns {string}
     */
    adornData(data = {}, openDefultdata = true, contentType = 'json') {
        let defaults = {
            't': new Date().getTime()
        };
        let data1 = openDefultdata ? merge(defaults, data) : data;
        return contentType === 'json' ? JSON.stringify(data1) : qs.stringify(data1);
    },
    postForm(url, params, defultParams = false) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) {
            config.data = this.adornData(params, defultParams, 'form')
        }
        return request(config)
    },
    postJson(url, params, defultParams = false) {
        const config = {
            method: 'post',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            url: url
        }
        if (params) {
            config.data = this.adornData(params, defultParams, 'json')
        }
        return request(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    delete(url, params) {
        const config = {
            method: 'delete',
            url: url
        }
        if (params) config.params = params
        return request(config)
    }
}
// 导出
export default http
Vue.prototype.apis = http;
