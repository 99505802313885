<template>
  <Modal
    v-model="isShow"
    title="提示"
    :footer-hide="true"
    class-name="planDetailModal"
    :mask-closable="false"
    :closable="false"
  >
    <h1>系统检测到长时间未操作，已自动退出</h1>
    <div class="bths">
      <button @click="goOut">确定</button>
    </div>
  </Modal>
</template>

<script>
import router from "../router";
export default {
  props: {
    isShow: {
      // 整个遮罩和弹窗是否显示
      type: Boolean,
      default: Boolean,
    },
  },
  data() {
    return {
      toJSON: {},
    };
  },
  mounted() {
    if (window.location.pathname === "/login") {
      this.isShow = false;
    }
  },
  methods: {
    goOut() {
      localStorage.clear();
      sessionStorage.clear();
      if (window.location.pathname !== "/login") {
        router.replace("/login");
      } else {
        this.isShow = false;
      }
      this.isShow = false;
      document.querySelectorAll(".v-transfer-dom").forEach((element) => {
        console.log(element.childNodes);
        document.body.removeChild(element);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.planDetailModal {
  .ivu-modal {
    width: 700px !important;
    height: 350px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 50%;
    margin-top: -175px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
  }
  .ivu-modal-content {
    height: 350px;
    border-radius: 20px;
  }
  /* 关闭图标 */
  .ivu-modal-close {
    top: 14px;
    .ivu-icon-ios-close {
      font-size: 52px;
      color: #333333;
    }
  }
  .ivu-modal-header {
    border-radius: 20px 20px 0 0;
    padding: 27px 24px;
    height: 85px;
    box-sizing: border-box;
    background: #e5f2fc;
    .ivu-modal-header-inner {
      width: 100%;
      height: 100%;
      line-height: 100%;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
  }
  .ivu-modal-body {
    border-radius: 0 0 20px 20px;
    width: 700px;
    height: 265px;
    background: #ffffff;
    padding: 40px;
    box-sizing: border-box;
    overflow-y: auto;
    h1 {
      font-size: 30px;
      text-align: center;
    }
  }
  .bths {
    margin-top: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 200px;
      height: 54px;
      background: #ffffff;
      border-radius: 9px;
      box-sizing: border-box;
      border: none;
      font-size: 24px;
      font-weight: bold;
      border: 1px solid #4174f7;
      color: #4174f7;
      cursor: pointer;
      &:hover {
        background: rgba(65, 116, 247, 0.07);
      }
    }
  }
}
</style>