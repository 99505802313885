
import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
// 解决跳转到相同路由报错的问题
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: '',
        redirect: {
            path: '/login'
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        props: (route) => ({ username: route.query.username, password: route.query.password })
    },
    {
        path: '/loginchoose',
        name: 'loginchoose',
        meta: {
            title: '选择患者'
        },
        component: () => import(/* webpackChunkName: "login2" */ '../views/LoginChoose.vue'),
    },
    {
        path: '/main',
        name: 'Main',
        meta: {
            title: '首页'
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Main.vue'),
        children: [
            {
                // path为空即默认页面 home.vue
                path: '',
                name: 'first',
                meta: {
                    title: '首页'
                },
                component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
            },
            {
                // path为空即默认页面 home.vue
                path: 'home',
                name: 'home',
                meta: {
                    title: 'home'
                },
                component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
            },
            // 患者端-评测报告
            {
                path: '/main/patientReport',
                name: 'patientReport',
                meta: {
                    title: '训练报告'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/PatientReport.vue'),
            },
            // 患者端-训练日志
            {
                path: '/main/patientrecord',
                name: 'patientrecord',
                meta: {
                    title: '训练日志'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/PatientRecord.vue'),
            },
            // 患者端-脑力指数
            {
                path: '/main/brainstatistics',
                name: 'brainstatistics',
                meta: {
                    title: '脑力指数'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/BrainStatistics.vue'),
            },
            // 跳转到训练详情页
            {
                path: '/main/traininfo',
                name: 'traininfo',
                meta: {
                    title: '脑力指数'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/TrainInfo.vue'),
            },
            // 前往报告详情页面
            {
                path: '/main/reportDetails',
                name: 'reportDetails',
                meta: {
                    title: '报告详情'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/components/reportDetails.vue'),
            },
            // 前往日志详情页面
            {
                path: '/main/recordDetails',
                name: 'recordDetails',
                meta: {
                    title: '日志详情'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/components/recordDetails.vue'),
            },
            // 今日指数 雷达图
            {
                path: '/main/todaystatistics',
                name: 'todaystatistics',
                meta: {
                    title: '今日指数'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/TodayStatistics.vue'),
            },
            // 脑力指数-阶段报告
            {
                path: '/main/stagereport',
                name: 'stagereport',
                meta: {
                    title: '阶段报告'
                },
                component: () => import(/* webpackChunkName: "homedata" */ '../views/patientend/StageReport.vue'),
            },
        ]
    },
    {
        path: '/mmsereport',
        name: 'mmsereport',
        meta: {
            title: 'mmse量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/mmsereport.vue'),
    },
    {
        path: '/avltreport',
        name: 'avltreport',
        meta: {
            title: 'avlt量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/avltreport.vue'),
    },
    {
        path: '/adlreport',
        name: 'adlreport',
        meta: {
            title: 'adl量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/adlreport.vue'),
    },
    {
        path: '/hamdreport',
        name: 'hamdreport',
        meta: {
            title: 'hamd量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/hamdreport.vue'),
    },
    {
        path: '/mocareport',
        name: 'mocareport',
        meta: {
            title: 'moca量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/mocareport.vue'),
    },
    {
        path: '/psqireport',
        name: 'psqireport',
        meta: {
            title: 'psqi量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/psqireport.vue'),
    },
    {
        path: '/hamareport',
        name: 'hamareport',
        meta: {
            title: 'hama量表'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/hamareport.vue'),
    },
    {
        path: '/newreport',
        name: 'newreport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/newTestReport.vue'),
    },
    {
        path: '/pubhealthreport',
        name: 'pubhealthreport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newreports/pubhealthReport.vue'),
    },
    // adl报告详情 维度显示
    {
        path: '/newadlreport',
        name: 'newadlreport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/ADLReport.vue'),
    },
    // avlt报告详情 维度显示
    {
        path: '/newavltreport',
        name: 'newavltreport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/AVLTReport.vue'),
    },
    // hama报告详情 维度显示
    {
        path: '/newhamareport',
        name: 'newhamareport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/HAMAReport.vue'),
    },
    // hamd报告详情 维度显示
    {
        path: '/newhamdreport',
        name: 'newhamdreport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/HAMDReport.vue'),
    },
    // mmse报告详情 维度显示
    {
        path: '/newmmsereport',
        name: 'newmmsereport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/MMSEReport.vue'),
    },
    // moca报告详情 维度显示
    {
        path: '/newmocareport',
        name: 'newmocareport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/MOCAReport.vue'),
    },
    // psqi报告详情 维度显示
    {
        path: '/newpsqireport',
        name: 'newpsqireport',
        meta: {
            title: ''
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/patient/newtestreports/PSQIReport.vue'),
    },
    {
        path: '/mainfixheader',
        name: 'MainFixHeader',
        meta: {
            title: '测试固定表头'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/demo/MainFixHeader.vue'),
    },
    // {
    //     path: '/testapi',
    //     name: 'testapi',
    //     meta: {
    //         title: '测试api请求'
    //     },
    //     component: () => import(/* webpackChunkName: "demo" */ '../views/demo/TestHttps.vue'),
    // }

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
router.beforeEach((to, from, next) => {
    // if (to.meta && to.meta.title){
    //     document.title = '脑健康康复训练管理系统' + to.meta.title
    // }
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = require('../assets/img/logo.png')
    document.title = '认知功能康复训练与评估软件'
    next()
})
